import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import {
  IoIosSwap,
  IoIosCalendar,
  IoIosDisc,
  IoIosPeople,
  IoIosCalculator,
  IoIosListBox,
  IoIosSunny,
} from "react-icons/io";

import Image from "../components/image";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { CallToAction, ResourceCard, CustomerQuote } from "../components/site/";

import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";

const CardRow = styled(Row)`
  width: calc(100% + 30px);
  align-items: stretch;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const HolidaysActCompliance = (props) => {
  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulResource(
        sort: { order: DESC, fields: publishedDate }
        filter: {
          resourceTitle: {
            in: [
              "How compliant is your payroll? Questions to ask yourself (or your payroll provider)."
              "What is Payroll Compliance?"
              "Understanding New Zealand’s payroll compliance nightmare"
            ]
          }
        }
      ) {
        edges {
          node {
            publishedDate
            resourceImage {
              gatsbyImageData(width: 650, layout: FIXED, quality: 100)
            }
            resourceTitle
            resourceType
            resourceTopic
            resourceContent {
              raw
            }
            excerpt
            url
            author
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title="Holidays Act Compliance | Pay Employees Right | PayHero"
        description="PayHero handles employee leave in line with the Holidays Act 2003 and the latest government guidance."
        pathname={props.location.pathname}
      />
      <Container className="-textCenter font-bump">
        <Wrapper maxWidth={950} stackGap={80}>
          <Box stackGap={30}>
            <img
              className="-center"
              src={PayHeroLogo}
              alt="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
              css={{
                width: "40%",
                maxWidth: "220px",
                maxHeight: "40px",
                marginTop: "0 !important",
                marginBottom: "0 !important",
              }}
            />
            <Box stackGap={5}>
              <h1>
                <span key={0} className="-fontLight">
                  Employee leave,
                </span>{" "}
                done right
              </h1>
              <h4>
                Don’t let payroll compliance keep you up at night. PayHero
                handles employee leave in line with the Holidays Act 2003 and
                the latest government guidance.
              </h4>
            </Box>
          </Box>
        </Wrapper>
      </Container>
      {/* <Container bg="purple" className="-textCenter">
        <Wrapper stackGap={30}>
          <h4 css={{ color: "#fff", marginBottom: "0 !important" }}>
            <IoIosAlert css={{ top: "2px", position: "relative" }} /> MYOB has
            identified compliance issues with seven of its payroll products. In
            some situations, leave rates or entitlements for employees may not
            have been calculated in line with the New Zealand Holidays Act 2003.
            Find out about switching to PayHero from{" "}
            <Link
              
              css={{ color: "#fff" }}
              to="/myob-payroll"
            >
              MYOB Payroll
            </Link>
            ,{" "}
            <Link
              
              css={{ color: "#fff" }}
              to="/ace-payroll"
            >
              Ace Payroll
            </Link>{" "}
            or{" "}
            <Link
              
              css={{ color: "#fff" }}
              to="/ims-payroll"
            >
              IMS Payroll
            </Link>
            .
          </h4>
        </Wrapper>
      </Container> */}
      <Container className="secondary">
        <Wrapper maxWidth={800} className="font-bump">
          <h2 className="-textCenter" css={{ marginBottom: "-40px!important" }}>
            What’s the issue with payroll compliance in NZ?
          </h2>
          <div>
            <p>
              Widespread problems with Holidays Act compliance have hit the news
              in recent years, with large organisations and government agencies
              paying out millions to employees to cover historical
              underpayments.
            </p>
            <p>
              While the issues encompass a wide range of payroll calculations,
              the main area for concern is the calculation of employee leave
              balances when leave is accrued based on hours (or days) actually
              worked.
            </p>
            <p>
              The Holidays Act 2003 provides employees with four <b>weeks</b> of
              annual leave for each year of employment, regardless of the number
              of hours worked in the previous year.
            </p>
            <p>
              In 2017 the Ministry for Business, Innovation and Employment
              released updated{" "}
              <a
                href="https://www.employment.govt.nz/assets/Uploads/d53229842c/holidays-act-2003-guidance-holidays-leave-.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Holidays Act guidance
              </a>{" "}
              that specifically discourages accruing leave with each pay.
            </p>
            <blockquote css={{ color: "#fff" }}>
              “It is strongly advised that annual holidays balances are kept in
              weeks. This will reduce the risk of non-compliance. Keeping
              balances in units other than weeks can lead to non-compliance if
              work patterns change.” p.32
            </blockquote>
            <p>
              We built PayHero to handle leave correctly in line with the
              Holidays Act 2003 and the latest guidance from MBIE.{" "}
              <Link to="/signup">Join PayHero now</Link> and leave compliance
              concerns behind.
            </p>
            <p>
              <b>Read more:</b>{" "}
              <Link to="/blog/whats-up-with-so-many-new-zealand-businesses-announcing-remedial-payments-for-underpaid-holiday">
                Holiday Pay Problems in New Zealand
              </Link>
            </p>
          </div>
          <CustomerQuote
            quote="The nightmare of public holiday calculations is not my problem anymore."
            name="Pippa Scott"
            company="The Gelato Lab"
            pic="GelatoLab_Circle.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <div className="-textCenter">
            <h2 className="h1">Stress-free Holidays Act compliance</h2>
          </div>
          <Row alignCenter stackGap={60}>
            <Box stackGap={60} size={50}>
              <div>
                <h3>
                  <IoIosCalendar />
                  <br />
                  Annual leave in weeks
                </h3>
                <p>
                  PayHero stores annual leave in weeks and determines employee
                  entitlements at the time they take leave. MBIE advises that
                  keeping leave balances in hours or days (like most payroll
                  systems) can lead to non-compliance if work patterns change.
                </p>
              </div>
              <div>
                <h3>
                  <IoIosDisc />
                  <br />
                  Accurate when work patterns change
                </h3>
                <p>
                  Many payroll systems accrue annual leave with each pay.
                  However, if an employee’s work pattern changes the accrued
                  balance may no longer represent four weeks of leave. PayHero
                  doesn’t accrue leave - instead we show you and your employees
                  current leave due and estimated leave in advance. Leave is
                  evaluated when it is taken, rather than being based on what
                  employees have hypothetically accrued or ‘earnt’ over time.
                </p>
              </div>
              <div>
                <h3>
                  <IoIosPeople />
                  <br />
                  Get entitlements right for variable hour staff
                </h3>
                <p>
                  For employees without a consistent or agreed work pattern
                  PayHero uses a review period to determine what a 'week' of
                  leave looks like. When the employee takes leave we use the
                  review period to calculate what portion of the annual holiday
                  entitlement is being taken.
                </p>
              </div>
            </Box>
            <Box size={50}>
              <Image
                filename="HolidayLeaveAvailable_PayrollCompliance.png"
                alt="PayHero | Pay Employees Right | Holidays Act Compliance"
                maxWidth={550}
                centerImage
                addShadow
                rounded
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="The only software on the market currently able to accurately calculate the leave entitlements for employees with irregular working patterns, without manual intervention, is PayHero."
            name="Irene Bennetts"
            company="Admin Army"
            pic="Irene_Circle.png"
            large
            center
          />
          <Row alignCenter stackGap={60} mobileReverse>
            <Box size={50}>
              <Image
                filename="AnnualLeaveTakenDetails_PayrollCompliance.png"
                alt="PayHero | Pay Employees Right | Holidays Act Compliance"
                maxWidth={550}
                centerImage
                addShadow
                rounded
              />
            </Box>
            <Box stackGap={60} size={50}>
              <div>
                <h3>
                  <IoIosCalculator />
                  <br />
                  Use timesheet history to calculate leave rates
                </h3>
                <p>
                  To properly calculate leave entitlements for variable hour
                  employees you need a full record of hours and days worked.
                  Combining online timesheets and payroll in a single system
                  means PayHero can automatically calculate the right
                  entitlements for you.
                </p>
              </div>
              <div>
                <h3>
                  <IoIosListBox />
                  <br />
                  Transparent leave calculations
                </h3>
                <p>
                  The details of the Holidays Act can be hard for employees to
                  comprehend. PayHero gives your staff the numbers they need to
                  understand how their leave and holiday payments have been
                  calculated, so they can trust the entitlements and deductions
                  in their pay.
                </p>
              </div>
              <div>
                <h3>
                  <IoIosSunny />
                  <br />
                  Easy public holiday payroll
                </h3>
                <p>
                  No more painstaking public holiday calculations or costly
                  payroll errors! Save on paperwork and manual processing by
                  using timesheet history to automatically calculate public
                  holiday entitlements for variable work pattern employees.
                </p>
              </div>
            </Box>
          </Row>
          <CustomerQuote
            quote="My payroll takes me 10-15 minutes a week, and I’m able to answer all the questions from my team about their entitlements."
            name="Chris Kinnell"
            company="The Butcher’s Son"
            pic="TheButchersSon_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container className="primary -textCenter">
        <Wrapper maxWidth={1000} className="font-bump">
          <Box stackGap={15}>
            <h2>
              <IoIosSwap
                css={{ fontSize: "4rem", opacity: "0.5", marginBottom: "10px" }}
              />{" "}
              <br />
              Need help switching to PayHero?
            </h2>
            <h4>
              If you have concerns about your current payroll compliance we can
              provide advice about how to review your historical leave balances
              and entitlements and help you enter those into PayHero. Just
              contact{" "}
              <a href="mailto:support@payhero.co.nz">support@payhero.co.nz</a>{" "}
              and we’ll get you sorted.
            </h4>
          </Box>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <div className="-textCenter">
            <h2 className="h1">Read more about payroll compliance</h2>
          </div>
          <CardRow isWrap justify="flex-start">
            {contentfulData.allContentfulResource.edges.map((edge, i) => {
              return (
                <ResourceCard
                  isFeatured={false}
                  key={i}
                  image={edge.node.resourceImage.gatsbyImageData}
                  type={edge.node.resourceType}
                  topic={edge.node.resourceTopic}
                  title={edge.node.resourceTitle}
                  excerpt={edge.node.excerpt}
                  date={edge.node.publishedDate}
                  url={edge.node.url}
                  words={edge.node.resourceContent.raw}
                  show={true}
                />
              );
            })}
          </CardRow>
        </Wrapper>
      </Container>
      <CallToAction
        title="Take payroll compliance seriously"
        description="Join other Kiwi companies paying employees right."
        buttonText="Get Started for Free"
      />
    </Layout>
  );
};

export default HolidaysActCompliance;
